import CloudFun from "@cloudfun/core";
import { App } from "vue"
import faker from "./faker"
import helper from "./helper"
import lodash from "./lodash"
import phoneNumber, { CountryCode } from "libphonenumber-js";
import formatDate from "xe-utils/toDateString";

export default (app: App): void => {
  app.use(faker)
  app.use(helper)
  app.use(lodash)

  const filters = {
    formatPhone: (number: string, country: CountryCode = "TW") =>
      number && phoneNumber(number, country)?.formatNational()?.replaceAll(' ', ''),
    formatDate
  }

  app.config.globalProperties.$filters = filters;
  Object.assign(CloudFun.utils, filters);
}
