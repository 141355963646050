import { renderSlot as _renderSlot, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_highlight = _resolveDirective("highlight")!

  return _withDirectives((_openBlock(), _createElementBlock("pre", null, [
    _createTextVNode("    "),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createTextVNode("\n    "),
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.copySource) = $event)),
      class: "fixed w-1 h-1"
    }, null, 512), [
      [_vModelText, _ctx.copySource]
    ]),
    _createTextVNode("\n  ")
  ])), [
    [_directive_highlight]
  ])
}