import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import actionLog from "./action-log";
import category from "./category";
import bannerPosition from "./banner-position";
import banner from "./banner";
import pictureAlbum from "./picture-album";
import picture from "./picture";

import area from "./area";
import attribute from "./attribute";
import bike from "./bike";
import calendar from "./calendar";
import comment from "./comment";
import commissionRecord from "./commission-record";
import insuranceRecord from "./insurance-record";
import invoice from "./invoice";
import invoiceTrack from "./invoice-track";
import faq from "./faq";
import member from "./member";
import news from "./news";
import order from "./order";
import orderItem from "./order-item";
import payment from "./payment";
import product from "./product";
import rentingRecord from "./renting-record";
import shoppingCart from "./shopping-cart";
import station from "./station";
import store from "./store";
import tag from "./tag";
import template from "./template";

const model = new Model("Fastbike", {
  main,
  configuration,
  role,
  user,
  actionLog,
  category,
  bannerPosition,
  banner,
  pictureAlbum,
  picture,
  area,
  attribute,
  bike,
  calendar,
  comment,
  commissionRecord,
  insuranceRecord,
  invoice,
  invoiceTrack,
  faq,
  member,
  news,
  order,
  orderItem,
  payment,
  product,
  rentingRecord,
  shoppingCart,
  station,
  store,
  tag,
  template
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data =>
  model.clients.unauthorized.post("System/Login", {
    account: data.account,
    password: data.password,
    token: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha
  });
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = message => model.clients.unauthorized.post("System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
