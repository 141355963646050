import { PolicyRule } from '@cloudfun/core/dist/policy'
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  { path: 'configuration', component: () => import('./views/configuration/Main.vue'), meta: { rule: 'Configuration' } },
  { path: 'action-log', component: () => import('./views/action-log/Main.vue'), meta: { rule: 'ActionLog' } },
  { path: 'user-wizard', component: () => import('./views/user-wizard/Main.vue'), meta: { rule: new PolicyRule('Role').and('User') } },
  { path: 'role', component: () => import('./views/role/Main.vue'), meta: { rule: 'Role' } },
  { path: 'user', component: () => import('./views/user/Main.vue'), meta: { rule: 'User' } },
  {
    path: "category/product",
    component: () => import("./views/category/Product.vue")
  },
  {
    path: "category/store",
    component: () => import("./views/category/Store.vue")
  },
  { path: 'banner', component: () => import('./views/banner/Main.vue'), meta: { rule: 'Banner' } },
  { path: "album", component: () => import("./views/album/Main.vue") },
  {
    path: "tag",
    component: () => import("./views/tag/Main.vue"),
    meta: { rule: 'Tag' }
  },
  {
    path: "area",
    component: () => import("./views/area/Main.vue"),
    meta: { rule: 'Area' }
  },
  {
    path: "bike",
    component: () => import("./views/bike/Main.vue"),
    meta: { rule: 'Bike' }
  },
  {
    path: "bike/estimate",
    component: () => import("./views/bike/estimate/Main.vue"),
    meta: { rule: 'Bike' }
  },
  {
    path: "bike/order",
    component: () => import("./views/bike/orderBike/Main.vue"),
    meta: { rule: 'Bike' }
  },
  {
    path: "bike/quantity",
    component: () => import("./views/bike/quantity/Main.vue"),
    meta: { rule: 'Bike' }
  },
  {
    path: "bike/transfer",
    component: () => import("./views/bike/transfer/Main.vue"),
    meta: { rule: 'Bike' }
  },
  {
    path: "station",
    component: () => import("./views/station/Main.vue"),
    meta: { rule: 'Station' }
  },
  // {
  //   path: "productCategory",
  //   component: () => import("./views/product-category/Main.vue")
  // },
  {
    path: "product",
    component: () => import("./views/product/Main.vue"),
    meta: { rule: 'Product' }
  },
  {
    path: "store",
    component: () => import("./views/store/Main.vue"),
    meta: { rule: 'Store' }
  },
  {
    path: "order",
    component: () => import("./views/order/Main.vue"),
    meta: { rule: 'Order' }
  },
  {
    path: "order/done",
    component: () => import("./views/order/Done.vue"),
    meta: { rule: 'Order' }
  },
  {
    path: "order/payment",
    component: () => import("./views/order/Payment.vue"),
    meta: { rule: 'Order' }
  },
  { path: 'comment', component: () => import('./views/comment/Main.vue'), meta: { rule: 'Member' } },
  { path: 'faq', component: () => import('./views/faq/Main.vue'), meta: { rule: 'Faq' } },
  { path: 'member', component: () => import('./views/member/Main.vue'), meta: { rule: 'Member' } },
  { path: 'template/:name', component: () => import('./views/template/Main.vue'), meta: { rule: 'Template' } },
  { path: 'commission', component: () => import('./views/commission/Main.vue'), meta: { rule: 'CommissionRecord' } },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/bike/order",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "/bike/order",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "/bike/order",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
