import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b00af2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_picker = _resolveDirective("picker")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    value: _ctx.modelValue
  }, null, 8, _hoisted_1)), [
    [_directive_picker, { props: _ctx.props, emit: _ctx.emit }]
  ])
}